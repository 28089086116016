import { Box, Center, HStack, Menu, Pressable, ScrollView, Text, VStack } from 'native-base';
import 'react-tabs/style/react-tabs.css';
import { SearchInput } from '../components/SearchInput';
import ResultTally from '../components/ResultTally';
import TrackingResultRow, {
  Props as TrackingResultRowProps,
} from '../components/TrackingResultRow';
import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { StateTab, TrackingResult } from '../types';
import { arraysEqual, downloadTrackingData } from '../helper';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import { ImageDialog } from '../components/ImageDialog';

export type TrackingNumberResultHash = {
  trackingNumber: string;
  trackingResult: TrackingResult | undefined;
  fetchStatus: 'idle' | 'loading' | 'error' | 'success';
};

const ResultScreen = () => {
  const location = useLocation();
  const [trackingNumberResultHashList, setTrackingNumberResultHashList] =
    useState<TrackingNumberResultHash[]>([]);
  const [activeFilter, setActiveFilter] = useState<StateTab>('all');
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [imagePath, setImagePath] = useState<string>("")

  useEffect(() => {
    const searchQueryStr = new URLSearchParams(location.search);
    const searchTrackingNumbers = searchQueryStr.getAll('tn[]');
    const trackingNumbers = trackingNumberResultHashList.map(
      tnrh => tnrh.trackingNumber,
    );

    if (arraysEqual(trackingNumbers, searchTrackingNumbers)) {
      return;
    }

    const newTrackingNumberResultHashList = searchTrackingNumbers.map(tn => {
      const matchingRecord = trackingNumberResultHashList.find(
        tnrh => tnrh.trackingNumber === tn,
      );

      if (!matchingRecord) {
        return {
          trackingNumber: tn,
          trackingResult: undefined,
          fetchStatus: 'idle' as TrackingNumberResultHash['fetchStatus'],
        };
      } else {
        return matchingRecord;
      }
    });

    setTrackingNumberResultHashList(newTrackingNumberResultHashList);
  }, [location.search, trackingNumberResultHashList]);

  const handleTrackingDataExport = useCallback(
    (
      format: Parameters<typeof downloadTrackingData>[1],
      mostRecent: Parameters<typeof downloadTrackingData>[2],
    ) => downloadTrackingData(trackingNumberResultHashList, format, mostRecent),
    [trackingNumberResultHashList],
  );

  const handleTrackingResultFetch: TrackingResultRowProps['trackingNumberResultFetchHandler'] =
    (trackingNumber, result) => {
      return setTrackingNumberResultHashList(list =>
        list.map(tnrh => {
          if (tnrh.trackingNumber === trackingNumber) {
            if (result === 'loading') {
              return { ...tnrh, fetchStatus: 'loading' };
            }

            if (result === undefined) {
              return { ...tnrh, fetchStatus: 'error' };
            }

            return { ...tnrh, trackingResult: result, fetchStatus: 'success' };
          }

          return tnrh;
        }),
      );
    };

    const closeImageModal = () => {
      setOpenDialog(false)
      setImagePath('')
    }

  return (
    <div className="landing_container" style={{ zIndex: 3 }}>
      <Navbar upper={false} />
      <Box width="70%" mt={5}>
        <ScrollView width="100%">

          <div style={{ boxShadow: '0px 8px 20px #0000000D', border: '1px solid #D1D5DB', borderRadius: "34px", padding: "5px 5px 0px 5px", width: '90%', marginLeft: '5%' }} >
            <SearchInput onlyArrow={true} />
          </div>
          <HStack mt="54px" width="100%" justifyContent="space-between">
            <Box width="322px">
              <Text color="#293d8b" textAlign="left" fontWeight="bold" fontSize="35px" fontStyle="italic" fontFamily="Segoe UI" letterSpacing={0}>随访结果</Text>
              <Box mt={4} mb={3}>
                <Text color="#000000" textAlign="left" fontStyle="italic" fontSize="18px" fontFamily="Segoe UI" textTransform="uppercase" fontWeight="500">需要帮忙？</Text>
                <Box height="3px" width="81px" backgroundColor="#ff9300" mt={2} />
              </Box>
              <Link to={`/help#${t('help_1')}`}>
              <Pressable p={0} m={0}>
                {({
                  isHovered,
                }) => {
                  return <Text textAlign="left" fontSize="17px" color={isHovered ? "#293d8b" : "#121212"} fontFamily="Segoe UI" mt={4}>{t('help_1')}</Text>;
                }}
              </Pressable>
              </Link>
              <Link to={`/help#${t('help_12')}`}>
              <Pressable p={0} m={0}>
                {({
                  isHovered,
                }) => {
                  return <Text textAlign="left" fontSize="17px" color={isHovered ? "#293d8b" : "#121212"} fontFamily="Segoe UI" mt={4}>{t('help_12')}</Text>;
                }}
              </Pressable>
              </Link>
              <Link to={`/help#${t('help_16')}`}>
              <Pressable p={0} m={0}>
                {({
                  isHovered,
                }) => {
                  return <Text textAlign="left" fontSize="17px" color={isHovered ? "#293d8b" : "#121212"} fontFamily="Segoe UI" mt={4}>{t('help_16')}</Text>;
                }}
              </Pressable>
              </Link>
              <Link to={`/help#${t('help_19')}`}>
              <Pressable p={0} m={0}>
                {({
                  isHovered,
                }) => {
                  return <Text textAlign="left" fontSize="17px" color={isHovered ? "#293d8b" : "#121212"} fontFamily="Segoe UI" mt={4}>{t('help_19')}</Text>;
                }}
              </Pressable>
              </Link>

              <Box mt={5}>
                <Pressable>
                  {({
                    isHovered,
                  }) => {
                    return <Box width="206px" backgroundColor={isHovered ? "#ff9300" : "#ffb31c"} borderRadius="23px" height="47px" display="flex" flexDir="row" justifyContent="center" alignItems="center" >
                      <Text color="#293d8b" textTransform="uppercase" fontSize="16px" fontWeight="bold" fontFamily="Segoe UI">帮助中心</Text>
                    </Box>;
                  }}
                </Pressable>
              </Box>
            </Box>
            <VStack width={(window.innerWidth*0.7) - 322 + 'px'}>
              <HStack width="100%" justifyContent="space-between">
                <ResultTally
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  trackingNumberResultHashList={trackingNumberResultHashList}
                />
                {/* <HStack ml="20px" width="60%" justifyContent="space-between">
                  <Box borderBottomWidth="2px" borderBottomColor="#293d8b" pl={1} pr={1} pb={2}>
                    <HStack justifyContent="center" alignItems="center">
                      <Text fontSize="20px" color="#293d8b" fontFamily="Segoe UI" fontWeight="bold" fontStyle="italic">Todos</Text>
                      <Text letterSpacing="0.34px" fontWeight="semibold" fontFamily="Segoe UI" fontSize="15px">(7)</Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack justifyContent="center" alignItems="center">
                      <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/delivered.svg`, }} alt="Sobre despacho" width="28px" height="28px" />
                      <Text letterSpacing="0.34px" fontWeight="semibold" fontFamily="Segoe UI" fontSize="15px">(1)</Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack justifyContent="center" alignItems="center">
                      <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/transit.svg`, }} alt="Sobre despacho" width="28px" height="28px" />
                      <Text letterSpacing="0.34px" fontWeight="semibold" fontFamily="Segoe UI" fontSize="15px">(3)</Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack justifyContent="center" alignItems="center">
                      <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/no_change.svg`, }} alt="Sobre despacho" width="28px" height="28px" />
                      <Text letterSpacing="0.34px" fontWeight="semibold" fontFamily="Segoe UI" fontSize="15px">(1)</Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack justifyContent="center" alignItems="center">
                      <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/warning.svg`, }} alt="Sobre despacho" width="28px" height="28px" />
                      <Text letterSpacing="0.34px" fontWeight="semibold" fontFamily="Segoe UI" fontSize="15px">(1)</Text>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack justifyContent="center" alignItems="center">
                      <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/no_trace.svg`, }} alt="Sobre despacho" width="28px" height="28px" />
                      <Text letterSpacing="0.34px" fontWeight="semibold" fontFamily="Segoe UI" fontSize="15px">(1)</Text>
                    </HStack>
                  </Box>
                </HStack> */}
                <HStack mr="20px" justifyContent="flex-start">
                  <Menu
                    trigger={triggerProps => {
                      return (
                        <Pressable
                          accessibilityLabel="More options menu"
                          {...triggerProps}>
                          {({
                            isHovered,
                          }) => {
                            if (!isHovered) {
                              return <img src={`${process.env.PUBLIC_URL}/icons/download.svg`} alt="Sobre despacho" width="28px" height="28px" />;
                            }
                            return <img src={`${process.env.PUBLIC_URL}/icons/download_hover.svg`} alt="Sobre despacho" width="28px" height="28px" />;
                          }}
                        </Pressable>
                      );
                    }}>
                    {/*
                @ts-ignore */}
                    <Menu.Item onPress={() => handleTrackingDataExport('csv', true)}>
                      下载最新轨迹(CSV)
                    </Menu.Item>
                    {/*
                @ts-ignore */}
                    <Menu.Item onPress={() => handleTrackingDataExport('csv', false)}>
                      下载全部轨迹(CSV)
                    </Menu.Item>
                    {/*
                @ts-ignore */}
                    <Menu.Item onPress={() => handleTrackingDataExport('xlsx', true)}>
                      下载最新轨迹(XLSX)
                    </Menu.Item>
                    <Menu.Item
                      //@ts-ignore
                      onPress={() => handleTrackingDataExport('xlsx', false)}>
                      下载全部轨迹(XLSX)
                    </Menu.Item>
                  </Menu>
                </HStack>
              </HStack>
              {trackingNumberResultHashList
                .filter(tnrh =>
                  activeFilter === 'all'
                    ? true
                    : tnrh.trackingResult?.status === activeFilter,
                )
                .map(tnrh => {
                  return (
                    <TrackingResultRow
                      trackingNumberResultFetchHandler={
                        handleTrackingResultFetch
                      }
                      trackingNumberResultHash={tnrh}
                      setImage={setImagePath}
                      setOpenDialog={setOpenDialog}
                      key={tnrh.trackingNumber}
                    />
                  );
                })}
                {trackingNumberResultHashList
                .filter(tnrh =>
                  activeFilter === 'all'
                    ? true
                    : tnrh.trackingResult?.status === activeFilter,
                ).length === 0 && (
                  <Center flex={1}>
                    <VStack width="322px">
                      <Text bold fontSize="20px" color="#aaaaaa" fontFamily="Segoe UI" fontStyle="italic">{t("no_results_header")}</Text>
                      <Text fontSize="17px" color="#aaaaaa" fontFamily="Segoe UI">{t("no_results_text")}</Text>
                    </VStack>
                  </Center>
                )}
            </VStack>
          </HStack>
        </ScrollView>
      </Box>
      <Box width="100%" mt="40px" display="flex" flexDir="row" justifyContent="center" backgroundColor="#f6f6f6" pt="40px" position={window.innerWidth < 1920 ? "relative" : undefined}>
          <Footer />
        </Box>
        {openDialog && <ImageDialog img={imagePath} close={closeImageModal} />}
      
    </div>
  );
};

export default ResultScreen;
