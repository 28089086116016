import {
  Box, HStack,
  Image,
  Input,
  Pressable, Text,
  TextArea,
  VStack
} from 'native-base';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import { HelpCenter } from '../components/HelpCenter';
import Navbar from '../components/Navbar';
import { SearchInput } from '../components/SearchInput';
import '../styles/landing.css';

const FORM_SPACING_CONSTANT = 8;

const ContactScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="landing_container landing_gradient" style={{ zIndex: 3, height: '488px' }}>
      <Navbar upper={true} />
      <Box width="100%">
        <VStack left="16%" mt="100px" width="484px">
          <Text fontSize="60px" bold color="#FFB31C" fontStyle="italic">{t("contact us")}</Text>
          <SearchInput onlyArrow={true} />
        </VStack>
      </Box>
      <HStack width={window.innerWidth < 1900 ? "90%" :"70%"} justifyContent="space-between" mt="70px" mb="50px" alignItems="center">
        <Box width="325px">
          <VStack>
            <Text color="#293d8b" bold fontSize="35px" fontStyle="italic" fontFamily="Segoe UI">{t("about02")}</Text>
            <Box width="100px" height="3px" backgroundColor="#FF9300" mt="10px" mb="30px" />
          </VStack>
          <Text fontSize="17px" mb="30px" fontFamily="Segoe UI">{t("contact_01")}</Text>
          <Text fontSize="17px" fontFamily="Segoe UI">{t("contact_02")}</Text>
        </Box>
        <div style={{ width: '806px', height: '560px', background: '#ffffff', borderRadius: '10px', boxShadow: '0px 10px 30px #0000001A' }} >
          <VStack width="100%" alignItems="center" mt="20px">
            <HStack width="85%" justifyContent="space-between">
              <Text width="530px" fontSize="20px" fontFamily="Segoe UI">{t("contact_form_01")}</Text>
              <Image source={{ uri: `${process.env.PUBLIC_URL}/icons/contactUs_image.svg`, }} alt="contactus_image" width="143px" height="143px" position="absolute" right="0" top="-67px" />
            </HStack>
            <Box width="85%" mt="30px">
              <Input
                mb={FORM_SPACING_CONSTANT}
                size="lg"
                placeholder={t("contact_form_02")}
                borderColor="#d1d5db"
                borderRadius="6px"
              />
            </Box>

            <Box width="85%">
              <Input
                mb={FORM_SPACING_CONSTANT}
                size="lg"
                placeholder={t("contact_form_03")}
                borderColor="#d1d5db"
                borderRadius="6px"
              />
            </Box>
            <Box width="85%" mb={FORM_SPACING_CONSTANT}>
              <VStack width="90%">
                <Text bold fontFamily="Segoe UI" fontSize="17px">{t("contact_form_09")}</Text>
                <HStack mt="10px" justifyContent="space-between">
                  <Pressable>
                    {({
                      isHovered,
                    }) => {
                      return <Box backgroundColor={isHovered ? "#293d8b" : "#ffffff"} borderRadius="23px" pl="15px" pr="15px" pt="5px" pb="5px" >
                        <Text fontFamily="Segoe UI" fontSize="17px" color={isHovered ? '#ffffff' : '#000000'}>{t("contact_form_04")}</Text>
                      </Box>;
                    }}
                  </Pressable>
                  <Pressable>
                    {({
                      isHovered,
                    }) => {
                      return <Box backgroundColor={isHovered ? "#293d8b" : "#ffffff"} borderRadius="23px" pl="15px" pr="15px" pt="5px" pb="5px" >
                        <Text fontFamily="Segoe UI" fontSize="17px" color={isHovered ? '#ffffff' : '#000000'}>{t("contact_form_05")}</Text>
                      </Box>;
                    }}
                  </Pressable>
                  <Pressable>
                    {({
                      isHovered,
                    }) => {
                      return <Box backgroundColor={isHovered ? "#293d8b" : "#ffffff"} borderRadius="23px" pl="15px" pr="15px" pt="5px" pb="5px" >
                        <Text fontFamily="Segoe UI" fontSize="17px" color={isHovered ? '#ffffff' : '#000000'}>{t("contact_form_06")}</Text>
                      </Box>;
                    }}
                  </Pressable>
                </HStack>
              </VStack>
            </Box>

            <Box width="85%" >
              <TextArea
                h={20}
                placeholder={t("contact_form_07")}
                fontSize={'lg'}
                mb={FORM_SPACING_CONSTANT}
                borderColor="#d1d5db"
                borderRadius="6px"
              />
            </Box>
            <HStack width="85%">
              <Pressable>
                {({
                  isHovered,
                }) => {
                  return <Box width="119px" backgroundColor={isHovered ? "#ff9300" : "#ffb31c"} borderRadius="23px" height="47px" display="flex" flexDir="row" justifyContent="center" alignItems="center" >
                    <Text color="#293d8b" textTransform="uppercase" fontSize="16px" fontWeight="bold" fontFamily="Segoe UI">{t("contact_form_08")}</Text>
                  </Box>;
                }}
              </Pressable>
            </HStack>
          </VStack>
        </div>
      </HStack>
      <HelpCenter />
      <Box width="100%" mt="40px" flex={1} display="flex" flexDir="row" justifyContent="center" pt="40px">
        <Footer />
      </Box>
    </div>
  );
};

export default ContactScreen;
