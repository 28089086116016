import { Box, HStack, Image, Pressable, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import { HelpCenter } from '../components/HelpCenter';
import Navbar from '../components/Navbar';
import i18next from '../i18n';

const paragraphs = [
  i18next.t('about_1'),
  i18next.t('about_2'),
  i18next.t('about_3'),
  i18next.t('about_4'),
];

const AboutScreen = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <div className="landing_container landing_gradient" style={{ zIndex: 3, height: '536px' }}>
        <Navbar upper={true} />
        <div className="landing_image_container2">
          <Box width={window.innerWidth < 1900 ? "50%" : "35%"} left="16%">
            <Text color="#ffb31c" textAlign="left" fontStyle="italic" fontWeight={800} mb="40px" fontSize="60px" fontFamily="Segoe UI">{t("what_is")} <Text color="#fff">{t("31_track")}</Text></Text>
            <Text color="#ffffff" fontSize="25px" fontFamily="Segoe UI" width={window.innerWidth < 1900 ? "650px" : "855px"} mb="20px">{t('what_is_description')}</Text>
          </Box>
        </div>
      </div>
      <HStack width="100%" justifyContent="center">
        <HStack py={20} maxWidth="80%" mx={'auto'} alignItems="center">
          <Box>
            <Image ml={5} source={{ uri: `${process.env.PUBLIC_URL}/icons/follow.svg`, }} alt="Sobre despacho" width="309px" height="347px" />
          </Box>
          <VStack alignItems="flex-start">
            <Text px={12} color="#293d8b" textAlign="left" fontStyle="italic" fontWeight={800} mb="40px" fontSize="60px" fontFamily="Segoe UI">{t("about Us")}</Text>
            {paragraphs.map((text, i) => {
              return (
                <Text
                  color={'#555'}
                  fontSize={'xl'}
                  key={i}
                  mb={12}
                  width={856}
                  px={12}
                  textAlign="left">
                  {text}
                </Text>
              );
            })}
            <Pressable px={12}>
              {({
                isHovered,
              }) => {
                return <Link to="/contact"><Box width="206px" backgroundColor={isHovered ? "#ff9300" : "#ffb31c"} borderRadius="23px" height="47px" display="flex" flexDir="row" justifyContent="center" alignItems="center" >
                  <Text color="#293d8b" textTransform="uppercase" fontSize="16px" fontWeight="bold" fontFamily="Segoe UI">{t("get_in_touch")}</Text>
                </Box></Link>;
              }}
            </Pressable>
          </VStack>
        </HStack>
      </HStack>
      <HelpCenter />
      <Box width="100%" mt="40px" display="flex" flexDir="row" justifyContent="center" backgroundColor="#f6f6f6" pt="40px" position={window.innerWidth < 1920 ? "relative" : undefined}>
        <Footer />
      </Box>
    </Box>
  );
};

export default AboutScreen;
