import { Box, useColorMode } from 'native-base';

import HomeScreen from './screens/HomeScreen';
import ResultScreen from './screens/ResultScreen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ServiceScreen from './screens/ServiceScreen';
import ContactScreen from './screens/ContactScreen';
import AboutScreen from './screens/AboutScreen';
import { SecurityPolicy } from './screens/SecurityPolicy';
import { HelpScreen } from './screens/HelpScreen';
import { HelpQuestion } from './screens/HelpQuestion';

function App() {
  const { colorMode } = useColorMode();

  return (
    <Box
      bg={colorMode === 'light' ? 'white' : 'coolGray.800'}
      minHeight="100vh"
      minWidth="100%">
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/results" element={<ResultScreen />} />
          <Route path="/" element={<HomeScreen />} />
          <Route path="/service" element={<ServiceScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/security_policy" element={<SecurityPolicy />} />
          <Route path="/help" element={<HelpScreen />} />
          <Route path="/help/question/:id" element={<HelpQuestion />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
