import { Box, Pressable, Text } from 'native-base';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  upper: boolean;
}
const Navbar = ({ upper }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      height="76px"
      width="70%"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between">
      {/* <Link to="/" style={{ textDecoration: 'none' }}>
        <Image
          ml={5}
          source={{
            uri: `${process.env.PUBLIC_URL}${
              upper
                ? '/icons/logo_donex_transparente_blanco.png'
                : '/icons/logo_donex_transparente.png'
            }`,
          }}
          alt="Sobre despacho"
          width="135px"
          height="52px"
        />
      </Link> */}
      <div /> {/* Remove if the logo is needed again */}
      <Box
        width="528px"
        height="75px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
        {/*@ts-ignore */}
        <Link to="/about" style={{ textDecoration: 'none' }}>
          {/*@ts-ignore */}
          <Pressable>
            {({ isHovered }) => {
              return (
                <Box
                  p={0}
                  m={0}
                  pb={1}
                  borderBottomWidth="3px"
                  borderBottomColor={
                    isHovered ? (upper ? '#ffb31c' : '#293d8b') : 'transparent'
                  }>
                  <Text
                    fontSize="17px"
                    color={
                      isHovered
                        ? upper
                          ? '#ffffff'
                          : '#ffb31c'
                        : upper
                        ? '#ffb31c'
                        : '#293d8b'
                    }
                    textAlign="center"
                    fontWeight="semibold"
                    fontFamily="Segoe UI"
                    letterSpacing={0}
                    opacity={1}>
                    {t('about')}
                  </Text>
                </Box>
              );
            }}
          </Pressable>
        </Link>

        {/*@ts-ignore */}
        <Link to="/contact" style={{ textDecoration: 'none' }}>
          {/*@ts-ignore */}
          <Pressable>
            {({ isHovered }) => {
              return (
                <Box
                  p={0}
                  m={0}
                  pb={1}
                  borderBottomWidth="3px"
                  borderBottomColor={
                    isHovered ? (upper ? '#ffb31c' : '#293d8b') : 'transparent'
                  }>
                  <Text
                    fontSize="17px"
                    color={
                      isHovered
                        ? upper
                          ? '#ffffff'
                          : '#ffb31c'
                        : upper
                        ? '#ffb31c'
                        : '#293d8b'
                    }
                    textAlign="center"
                    fontWeight="semibold"
                    fontFamily="Segoe UI"
                    letterSpacing={0}
                    opacity={1}>
                    {t('contact us')}
                  </Text>
                </Box>
              );
            }}
          </Pressable>
        </Link>

        {/*@ts-ignore */}
        <Link to="/help" style={{ textDecoration: 'none' }}>
          {/*@ts-ignore */}
          <Pressable>
            {({ isHovered }) => {
              return (
                <Box
                  p={0}
                  m={0}
                  pb={1}
                  borderBottomWidth="3px"
                  borderBottomColor={
                    isHovered ? (upper ? '#ffb31c' : '#293d8b') : 'transparent'
                  }>
                  <Text
                    fontSize="17px"
                    color={
                      isHovered
                        ? upper
                          ? '#ffffff'
                          : '#ffb31c'
                        : upper
                        ? '#ffb31c'
                        : '#293d8b'
                    }
                    textAlign="center"
                    fontWeight="semibold"
                    fontFamily="Segoe UI"
                    letterSpacing={0}
                    opacity={1}>
                    {t('help')}
                  </Text>
                </Box>
              );
            }}
          </Pressable>
        </Link>

        {/*@ts-ignore */}
        <Link to="/security_policy" style={{ textDecoration: 'none' }}>
          {/*@ts-ignore */}
          <Pressable>
            {({ isHovered }) => {
              return (
                <Box
                  p={0}
                  m={0}
                  pb={1}
                  borderBottomWidth="3px"
                  borderBottomColor={
                    isHovered ? (upper ? '#ffb31c' : '#293d8b') : 'transparent'
                  }>
                  <Text
                    fontSize="17px"
                    color={
                      isHovered
                        ? upper
                          ? '#ffffff'
                          : '#ffb31c'
                        : upper
                        ? '#ffb31c'
                        : '#293d8b'
                    }
                    textAlign="center"
                    fontWeight="semibold"
                    fontFamily="Segoe UI"
                    letterSpacing={0}
                    opacity={1}>
                    {t('security policy')}
                  </Text>
                </Box>
              );
            }}
          </Pressable>
        </Link>
      </Box>
    </Box>
  );
};

export default Navbar;
