import { Box, Image, Input, Pressable, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function buildQueryStrFromTrackingNumbers(trackingNumbers: string[]): string {
  if (trackingNumbers.length === 0) {
    return '';
  }

  return trackingNumbers
    .filter(tn => {
      if (tn) return tn;
      return undefined;
    })
    .map(v => `tn[]=${v}`)
    .join('&');
}
export interface Props {
  onlyArrow?: boolean
}

export const SearchInput = ({onlyArrow}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [trackingNumbers, setTrackingNumbers] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value.includes(' ')) {
      const tmp = value
        .trim()
        .split(' ')
        .map(el => el.trim());

      const allTrackingNumbers = [...trackingNumbers, ...tmp];

      return navigateToUrlWithTrackingNumbers(
        location.pathname,
        allTrackingNumbers,
        true,
      );
    } else {
      return setInputValue(value);
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Space' && inputValue.trim() !== '') {
      const result = trackingNumbers.filter(el => el === inputValue.trim());
      if (result.length === 0) {
        const allTrackingNumbers = [...trackingNumbers, inputValue.trim()];
        setInputValue('');
        return navigateToUrlWithTrackingNumbers(
          location.pathname,
          allTrackingNumbers,
          true,
        );
      } else {
        alert('Ya ha introducido ese código');
        return setInputValue(inputValue.trim());
      }
    }

    if (event.code === 'Enter') {
      onSubmit();
    }
  };

  function navigateToUrlWithTrackingNumbers(
    pathname: string,
    trackingNumbers: string[],
    replace: boolean,
  ) {
    const queryStr = buildQueryStrFromTrackingNumbers(trackingNumbers);
    if (queryStr === '') {
      return navigate(`${pathname}`, { replace });
    } else {
      return navigate(`${pathname}?${queryStr}`, { replace });
    }
  }

  function onSubmit() {
    if (trackingNumbers.length === 0 && inputValue.trim() === '') {
      return;
    }

    const allTrackingNumbers =
      inputValue.trim() === ''
        ? trackingNumbers
        : [...trackingNumbers, inputValue.trim()];

    if (inputValue.trim().length > 0) {
      setInputValue('');
    }

    if (location.pathname === '/') {
      return navigateToUrlWithTrackingNumbers(
        '/results',
        allTrackingNumbers,
        false,
      );
    }

    if (location.pathname === '/results') {
      return navigateToUrlWithTrackingNumbers(
        location.pathname,
        allTrackingNumbers,
        true,
      );
    }

    new Error('Not configured to work on this page : ' + location.pathname);
  }

  useEffect(() => {
    const searchQueryStr = new URLSearchParams(location.search);
    const trackingNumbers = searchQueryStr.getAll('tn[]');
    setTrackingNumbers(trackingNumbers);
  }, [location.search]);

  return (
    <>
      {trackingNumbers.length > 0 && (
        <div className="landing_tags_container">
          <span
            onClick={() =>
              navigateToUrlWithTrackingNumbers(location.pathname, [], true)
            }
            className="container_close">
            &#x2716;
          </span>
          {trackingNumbers.map((trackingNumber: string, index: number) => (
            <div className="tag" key={index}>
              <span className="text">{trackingNumber}</span>
              <span
                onClick={() => {
                  const allTrackingNumbers = trackingNumbers.filter(
                    tn => tn !== trackingNumber,
                  );
                  navigateToUrlWithTrackingNumbers(
                    location.pathname,
                    allTrackingNumbers,
                    true,
                  );
                }}
                className="close">
                &#x2716;
              </span>
            </div>
          ))}
        </div>
      )}
      <Input
        height="67px"
        borderBottomLeftRadius="30px"
        borderBottomRightRadius="30px"
        borderTopLeftRadius={trackingNumbers.length > 0 ? 0 : '30px'}
        borderTopRightRadius={trackingNumbers.length > 0 ? 0 : '30px'}
        onKeyPress={onKeyPress}
        value={inputValue}
        onChange={onChangeInput}
        borderColor="#ffffff00"
        _focus={{ borderColor: '#ffffff00' }}
        backgroundColor="#ffffff"
        p={5}
        placeholder={t('Enter tracking number')}
        fontSize="16px"
        InputRightElement={
          <>
            {/*@ts-ignore */}
            <Pressable onPress={onSubmit}>
              {({
                isHovered,
              }) => {
                return <Box pl={4} pr={4} height="54px" width={onlyArrow ? "55px" : "152px"} borderRadius="30px" backgroundColor={isHovered ? "#ff9300" : "#ffb31c"} display="flex" flexDir="row" alignItems="center" justifyContent="center" >
                  {!onlyArrow && (
                  <Text fontSize="16px" fontWeight="bold" fontFamily="Segoe UI" color="#293d8b" textAlign="center" textTransform="uppercase" letterSpacing="0px">{t("locate")}</Text>
                  )}
                  <Image ml={onlyArrow ? 0 : 5} source={{ uri: `${process.env.PUBLIC_URL}/icons/locateArrow.svg`, }} alt="Sobre despacho" width="15px" height="15px" />
                </Box>;
              }}
            </Pressable>
          </>
          // <button className="landing_search_button" onClick={onSubmit}>
          //   <Box pl={4} pr={4} height="54px" width="152px" borderRadius="30px" backgroundColor="#ffb31c" display="flex" flexDir="row" alignItems="center" justifyContent="space-evenly">
          //     <Text color="#293D8B" fontSize="15px" fontWeight="bold" textAlign="center">LOCALIZAR</Text>
          //     <Image ml={5} source={{ uri: `${process.env.PUBLIC_URL}/icons/locateArrow.svg`, }} alt="Sobre despacho" width="15px" height="15px" />
          //   </Box>
          // </button>
        }
      />
    </>
  );
};
