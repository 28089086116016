// import { Brands } from "../components/Brands";
import { Landing } from '../components/Landing';
// import LandingHeroView from "../components/LandingHeroView";

const HomeScreen = () => {
  return (
    <>
      <Landing />
      {/* <Strengths /> */}
      {/* <World /> */}
      {/* <Footer /> */}
    </>
  );
};

export default HomeScreen;
