import { Box, HStack, Image, Pressable, Text, VStack } from "native-base";
import Footer from "../components/Footer";
import { paragraphs } from "../helper/security.helper";
import { useTranslation } from 'react-i18next';
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

export const SecurityPolicy = () => {
    const { t } = useTranslation();
    return (
        <Box>
            <div className="landing_container landing_gradient" style={{ zIndex: 3, height: '296px' }}>
                <Navbar upper={true} />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box top="50px">
                        <Text color="#ffb31c" textAlign="left" fontStyle="italic" fontWeight={800} fontSize={window.innerWidth < 1920 ? "53px" : "60px"} fontFamily="Segoe UI">{t('Privacy Policy')}</Text>
                    </Box>
                </div>
                <div style={{ top: '170px', right: "40px", position: 'absolute', width: '210px', height: '228px' }}>
                    <Image
                        width={'210px'}
                        height={'228px'}
                        style={{ position: 'absolute' }}
                        resizeMode={'cover'}
                        source={{
                            uri: `${process.env.PUBLIC_URL}/icons/help/lock.svg`,
                        }}
                    />
                </div>
            </div>
            <Box w="100%" display="flex" justifyContent="center" flexDir="row" mt="20px">
                <HStack px={'auto'} width="70%">
                    <VStack width="50%">
                        <VStack>
                            <Text bold fontSize="25px" color="#293d8b" italic fontFamily="Segoe UI">{t('table_of_contents')}</Text>
                            <Box maxWidth="100px" width="100%" height="3px" bg={'#ff9300'} />
                        </VStack>
                        {paragraphs.filter(el => el.type === "title").map((el, i) => {
                            return <Link to={`#${el.text}`} style={{ textDecoration: 'none' }}>
                                <Pressable p={0} m={0} mt="20px">
                                    {({ isHovered }) => {
                                        return (
                                            <Text
                                                color={'#1264A3'}
                                                fontSize="18px"
                                                fontFamily="Segoe UI"
                                                fontWeight="500"
                                                textDecorationLine={isHovered ? 'underline' : "none"}
                                                key={i}
                                                textAlign="left">
                                                {el.text}
                                            </Text>
                                        )
                                    }}</Pressable>
                            </Link>
                        })}
                    </VStack>
                    <VStack w="50%">
                        {paragraphs.map((text, i) => {
                            return (
                                <div id={text.text} style={{width: '100%', marginTop: text.type === 'text' ? '20px' : '40px'}}>
                                    <Text
                                        w="100%"
                                        italic={text.type === 'text' ? false : true}
                                        bold={text.type === 'text' ? false : true}
                                        color={text.type === 'text' ? "#000" : '#293d8b'}
                                        fontSize={text.type === 'text' ? '20px'  : '35px'}
                                        key={i}
                                        textAlign="left">
                                        {text.text}
                                    </Text>
                                </div>
                            );
                        })}
                    </VStack>
                </HStack>
            </Box>

            {/* <Box bg={'#fbfbfb'} py={20} maxWidth={768} mx={'auto'}>
                {paragraphs.map((text, i) => {
                    return (
                        <Text
                            bold={text.type === 'text' ? false : true}
                            color={'#555'}
                            fontSize={text.type === 'text' ? 'xl' : '2xl'}
                            key={i}
                            mb={text.type === 'text' ? 12 : 2}
                            px={12}
                            textAlign="left">
                            {text.text}
                        </Text>
                    );
                })}
            </Box> */}
            <Box width="100%" mt="40px" display="flex" flexDir="row" justifyContent="center" backgroundColor="#f6f6f6" pt="40px" position={window.innerWidth < 1920 ? "relative" : undefined}>
                <Footer />
            </Box>
        </Box>
    )
}